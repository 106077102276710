 const tourPosts = [
   {
     date:'September 13-14, 2024',
     venue: 'Symmetry Festival',
     location: 'Libertyville, IA, United States ',
     tickets:"https://www.tixr.com/groups/symmetryfestival/events/smf-95010"
   },
   {
     date:'September 3, 2024',
     venue: 'Afterburn Party',
     location: 'Reno, NV, United States ',
     tickets: ''
   }

 ];

 export default tourPosts;